import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { mask } from 'remask';
//
import { validaCpf } from '../../functions/validaCpf';
import { validaCnpj } from '../../functions/validaCnpj';
//
import apiPlay from '../../services/apiPlay';
import apiCep from '../../services/apiCep';
//
import Checkbox from '../Checkbox';
import Input from '../Input';
import Botao from '../Botao';
import Row from '../Row';
//
import AmexImg from '../../assets/logos/amex.svg';
import BanescardImg from '../../assets/logos/banescard.svg';
import CabalImg from '../../assets/logos/cabal.svg';
import DinnersImg from '../../assets/logos/diners.svg';
import DiscoverImg from '../../assets/logos/discover.svg';
import EloImg from '../../assets/logos/elo.svg';
import HipercardImg from '../../assets/logos/hipercard.svg';
// import JcbImg from '../../assets/logos/jcb.svg';
import MastercardImg from '../../assets/logos/mastercard.svg';
import VisaImg from '../../assets/logos/visa.svg';
import VaziaImg from '../../assets/bandeira_vazia.png';
import SslImg from '../../assets/site_seguro_imagem.png';

//
import styles from './TelaRecorrencia.module.css';
import { DragHandleDots2Icon, TrashIcon } from '@radix-ui/react-icons';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

const getBandeiraCartao = (value) => {
	switch (value) {
		case 'AMEX':
			return AmexImg

		case 'BANESCARD':
			return BanescardImg

		case 'CABAL':
			return CabalImg

		case 'DINERS':
			return DinnersImg

		case 'DISCOVER':
			return DiscoverImg

		case 'ELO':
			return EloImg

		case 'HIPERCARD':
			return HipercardImg

		// case 'JCB':
		// 	return JcbImg

		case 'MASTERCARD':
			return MastercardImg

		case 'VISA':
			return VisaImg

		case 'CREDZ':
			return VisaImg

		default:
			return VaziaImg
	}
}

export default function TelaRecorrencia({ fatura, payId, cartoesInitial, isRecActive }) {
	//STATES
	const [loadingPag, setLoadingPag] = useState(false);

	const [emailTitular, setEmailTitular] = useState('');
	const [cep, setCep] = useState('');
	const [endereco, setEndereco] = useState('');
	const [cpfCnpj, setCpfCnpj] = useState('');
	const [numCartao, setNumCartao] = useState('');
	const [nomeCartao, setNomeCartao] = useState('');
	const [mesVencimento, setmesVencimento] = useState('');
	const [anoVencimento, setAnoVencimento] = useState('');
	const [cvv, setCvv] = useState('');
	const [pagarAgora, setPagarAgora] = useState(false);
	const [dataCobranca, setDataCobranca] = useState('');
	const [mostraAddCartao, setMostraAddCartao] = useState(false);
	const [isEditCards, setIsEditCards] = useState(false);

	const [cartoes, setCartoes] = useState(cartoesInitial);

	// Vars
	const contaFatura = fatura.contafatura;
	const anoAtual = new Date().getFullYear().toString();

	//FUNCTION

	function realizaPagamento() {
		setLoadingPag(true);

		let dados = {
			payid: payId,
			pagacartao: '1',
			nomeCartao: nomeCartao,
			numero: numCartao,
			expirames: mesVencimento,
			expiraano: '20' + anoVencimento,
			ccv: cvv,
			nome: '',
			email: '',
			cpfCnpj: cpfCnpj,
			postalCode: '',
			endereconumero: '',
			enderecocomplemento: '',
			telefone: fatura.msisdn,
			telefonecelular: '',
		};

		apiPlay.post('/api/asaasfatura', dados).then((response) => {
			if (response.data.status === 'CONFIRMED') {
				toast.success('Pagamento Efetuado! Em alguns minutos sua linha será recarregada.');
				ativaRecorrencia();
			}

			if (response.data.status !== 'CONFIRMED') {
				if (!!response.data.errors.length) {
					toast.error(response.data.errors[0].description);
				} else {
					toast.error('Erro ao efetuar pagamento.');
				}

				setLoadingPag(false);
			}
		});
	}

	function ativaRecorrencia() {
		setLoadingPag(true);

		let dados = {
			cpf: fatura.cpf,
			statusrec: true,
			msisdn: fatura.msisdn,
		};

		apiPlay
			.post('/api/recorrencia/statusrecorrencia', dados)
			.then((response) => {
				toast.success('Recorrência ativada com sucesso!');

				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				if (error?.response?.status === 590) {
					toast.error('Cartão principal não foi cadastrado');
				} else {
					toast.error('Erro ao ativar recorrência');
				}
			}).finally(() => {
				setLoadingPag(false);
			});
	}

	function desativaRecorrencia() {
		setLoadingPag(true);

		let dados = {
			msisdn: fatura.msisdn,
			cpf: fatura.cpf,
			statusrec: false,
		};

		apiPlay
			.post('/api/recorrencia/statusrecorrencia', dados)
			.then((response) => {
				toast.info('Recorrência desativada com sucesso!');
				setLoadingPag(false);

				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				console.log(error);
				toast.error('Erro ao desativar recorrência');
				setLoadingPag(false);
			});
	}

	function addCard(e) {
		e.preventDefault();

		if (!!contaFatura && dataCobranca > 31) {
			toast.error('Informe um dia válido para cobrança');

			return
		}

		setLoadingPag(true);

		let dados = {
			name: nomeCartao,
			cpf: fatura.cpf,
			numerocartao: numCartao,
			expirames: mesVencimento,
			expiraano: '20' + anoVencimento,
			ccv: cvv,
			email: emailTitular,
			telefone: !!contaFatura ? undefined : fatura.msisdn,
			cep: cep,
			endereco: endereco,
			card_id: cartoes.length + 1,
			cpfcartao: cpfCnpj,
			vencimento: !!contaFatura ? dataCobranca : undefined,
		};

		apiPlay
			.post('/api/recorrencia/cadastraCartao', dados)
			.then((response) => {
				if (pagarAgora) realizaPagamento()

				toast.success('Cartão adicionado com sucesso!');

				setTimeout(() => {
					window.location.reload();
				}, 2000)
			})
			.catch((error) => {
				console.log(error);
				if (error?.response?.data?.error) {
					toast.error(error.response.data.error)
				} else {
					toast.error('Erro ao adicionar cartão');
				}
			}).finally(() => {
				setLoadingPag(false);
			});
	}

	const removeCard = (id) => {
		const confirmou = window.confirm('Deseja remover este cartão?');

		if (!confirmou) return

		setCartoes((prevCartoes) => prevCartoes.filter(cartao => cartao.cartão !== id));
	}

	const onSortEnd = (oldIndex, newIndex) => {
		setCartoes((array) => arrayMoveImmutable(array, oldIndex, newIndex))
	}

	const alteraOrdemCartoes = () => {
		setLoadingPag(true);

		const payload = {
			token: process.env.REACT_APP_TOKEN_API,
			card1: cartoes[0]?.cartão,
			card2: cartoes[1]?.cartão,
			card3: cartoes[2]?.cartão,
			cpf: fatura.cpf
		}

		setIsEditCards((prev) => !prev)

		apiPlay
			.post('/api/recorrencia/alteraCartoes', payload)
			.then((response) => {
				toast.success('Cartões atualizados com sucesso!');
			})
			.catch((error) => {
				console.log(error);
				toast.error('Erro ao atualizar cartões');
			}).finally(() => {
				setLoadingPag(false);
			});
	}

	//CICLOS
	useEffect(() => {
		function buscaCEP() {
			apiCep
				.get('/' + cep + '/json')
				.then((response) => {
					const res = response.data;

					setEndereco(`${res.uf} ${res.localidade} ${res.bairro} ${res.logradouro}`);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		if (cep.length === 8) {
			buscaCEP();
		}
	}, [cep]);

	//BODY
	return (
		<section className={styles['container']}>
			<>
				<span className={styles['title']}>
					Meus cartões
				</span>

				{cartoes.length >= 1 ? (
					<div className={styles['cartoes-container']}>
						<SortableList
							onSortEnd={onSortEnd}
							className={styles['sortable-container']}
							draggedItemClassName={styles['dragged']}
							lockAxis='y'
							allowDrag={cartoes.length > 1 && isEditCards}
						>
							{cartoes.map((cartao, index) => (
								<SortableItem key={cartao.cartão}>
									<div className={styles['cartao-container']}>
										<div className={styles['cartao']}>
											<span className={styles['secondary']}>Opção {index + 1}</span>
											<img
												className={styles['bandeiras']}
												src={getBandeiraCartao(cartao.bandeira)}
												alt="bandeira-cartao"
											/>
											<span>**** **** **** {cartao.ult4}</span>
										</div>


										{cartoes.length > 1 && isEditCards && (
											<SortableKnob>

												<DragHandleDots2Icon width={18} height={18} />
											</SortableKnob>
										)}
										{isEditCards && (

											<TrashIcon
												color="red"
												width={18}
												height={18}
												onClick={() => removeCard(cartao.cartão)}
											/>
										)}
									</div>

								</SortableItem>
							))}
						</SortableList>
					</div>
				) : (
					<span className={styles['secondary']} style={{ marginTop: '0.5rem' }}>
						Não há cartões cadastrados
					</span>
				)}

				<div style={{ display: "flex", gap: '1rem', flexWrap: "wrap" }}>
					{isRecActive ? (
						<Botao
							text='Desabilitar Recorrência'
							loading={loadingPag}
							color='error'
							style={{ margin: '0', marginTop: '1.5rem' }}
							onClick={desativaRecorrencia}
						/>
					) : (
						<Botao
							text='Ativar Recorrência'
							loading={loadingPag}
							color='primary'
							style={{ margin: '0', marginTop: '1.5rem', backgroundColor: 'green' }}
							onClick={ativaRecorrencia}
						/>
					)}

					{(cartoes.length > 0 || isEditCards) && (
						<Botao
							text={!isEditCards ? 'Alterar Cartões' : 'Salvar Alterações'}
							loading={loadingPag}
							color='primary'
							style={{ margin: '0', marginTop: '1.5rem' }}
							onClick={!isEditCards ? () => setIsEditCards((prev) => !prev) : alteraOrdemCartoes}
						/>
					)}




					<Botao
						disabled={cartoes.length >= 3 || isEditCards}
						text={!mostraAddCartao || isEditCards ? 'Adicionar novo cartão' : 'Cancelar'}
						loading={loadingPag}
						color='primary-g'
						style={{ margin: '0', marginTop: '1.5rem' }}
						onClick={() => setMostraAddCartao((prev) => !prev)}
					/>


				</div>

				{mostraAddCartao && !isEditCards && (
					<>
						<span className={styles['title']} style={{ marginTop: '1.75rem' }}>
							Informe os dados do cartão para finalizar o pagamento
						</span>

						<div className={styles['form-container']} style={{ marginTop: '1rem' }}>
							<form className={styles['form']} onSubmit={addCard}>
								<span className={styles['subtitle']}>Dados Cartão</span>

								<Row style={{ position: 'relative' }}>
									<Input
										value={mask(numCartao, ['9999 9999 9999 9999'])}
										onChange={(e) => {
											setNumCartao(mask(e.target.value, ['9999999999999999']));
										}}
										label='Número do cartão'
										required
									/>
									<img className={styles['bandeira-img']} src={VaziaImg} alt='bandeira-vazia' style={{ width: '50px', right: '12px' }} />
									{numCartao.startsWith('5') && (
										<img className={styles['bandeira-img']} src={MastercardImg} alt='bandeira-master' />
									)}
									{numCartao.startsWith('4') && (
										<img className={styles['bandeira-img']} src={VisaImg} alt='bandeira-visa' />
									)}
									{(numCartao.startsWith('34') || numCartao.startsWith('37')) && (
										<img className={styles['bandeira-img']} src={AmexImg} alt='bandeira-amex' />
									)}
									{(numCartao.startsWith('36') ||
										numCartao.startsWith('38') ||
										numCartao.startsWith('301') ||
										numCartao.startsWith('305')) && (
											<img className={styles['bandeira-img']} src={EloImg} alt='bandeira-elo' />
										)}
									{(numCartao.startsWith('60') || numCartao.startsWith('3841')) && (
										<img
											className={styles['bandeira-img']}
											src={HipercardImg}
											alt='bandeira-hipercard'
										/>
									)}
									{(numCartao.startsWith('6011') || numCartao.startsWith('65')) && (
										<img
											className={styles['bandeira-img']}
											src={DiscoverImg}
											alt='bandeira-discover'
										/>
									)}
								</Row>

								<Row>
									<Input
										value={nomeCartao}
										onChange={(e) => {
											setNomeCartao(e.target.value.toUpperCase());
										}}
										label='Nome (Igual no Cartão)'
										required
									/>
								</Row>

								<Row>
									<Input
										value={mesVencimento}
										onChange={(e) => {
											setmesVencimento(mask(e.target.value, ['99']));
										}}
										label='Mês'
										required
									/>

									<Input
										value={anoVencimento}
										onChange={(e) => {
											setAnoVencimento(mask(e.target.value, ['99']));
										}}
										label='Ano'
										required
									/>
								</Row>

								<Row>
									<Input
										value={cvv}
										onChange={(e) => {
											setCvv(mask(e.target.value, ['9999']));
										}}
										label='CVV (Código de Segurança)'
										required
									/>
								</Row>

								<Row>
									<Input
										value={mask(cpfCnpj, ['999.999.999-99', '99.999.999/9999-99'])}
										onChange={(e) => {
											setCpfCnpj(mask(e.target.value, ['99999999999', '99999999999999']));
										}}
										label='CPF ou CNPJ do Titular do Cartão'
										required
									/>
								</Row>

								<span className={styles['subtitle']}>Dados Pessoais</span>

								<Row>
									<Input
										type='email'
										value={emailTitular}
										onChange={(e) => {
											setEmailTitular(e.target.value);
										}}
										label='Email'
										required
									/>
								</Row>

								<Row>
									<Input
										type='tel'
										value={mask(cep, ['99999-999'])}
										onChange={(e) => {
											setCep(mask(e.target.value, ['99999999']));
										}}
										label='CEP'
										required
									/>
								</Row>

								{!!contaFatura && (
									<>
										<span className={styles['subtitle']}>Cobrança</span>

										<Row>
											<Input
												value={mask(dataCobranca, ['99'])}
												onChange={(e) => {
													setDataCobranca(mask(e.target.value, ['99']));
												}}
												label='Dia da cobrança'
												required
											/>
										</Row>
									</>
								)}

								<Checkbox
									id='PagarAgora'
									label='Pagar Fatura Atual?'
									checked={pagarAgora}
									onChange={() => setPagarAgora((prev) => !prev)}
								/>

								<Row>
									<Botao
										type='submit'
										text='Confirmar'
										style={{ width: '100%' }}
										loading={loadingPag}
										disabled={
											mesVencimento > '12' ||
											mesVencimento <= '0' ||
											anoVencimento < anoAtual ||
											(cpfCnpj.length > 11 && !validaCnpj(cpfCnpj || '00000000000000')) ||
											(cpfCnpj.length <= 11 && !validaCpf(cpfCnpj || '00000000000'))
										}
									/>
								</Row>
							</form>

							<div className={styles['ssl']}>
								<img src={SslImg} alt='site-seguro' />

								<span className={styles['txt-termos']}>
									<b>TERMOS E CONDIÇÕES</b>: Autorizo a{' '}
									<b>
										{fatura.nomeempresa?.toUpperCase()} | CNPJ{' '}
										{fatura.cnpj && mask(fatura.cnpj, ['99.999.999/9999-99'])}
									</b>{' '}
									a debitar em meu Cartão de Crédito o valor correspondente à <b>MENSALIDADE</b> dos
									compromissos da operadora móvel. Comprometo-me, desde já, a manter limite suficiente
									para o referido débito, declarando ciência de que a não liquidação do compromisso
									por insuficiência de limite na data do vencimento, não isenta a obrigação do
									pagamento. Em caso de dúvidas ou reclamação sobre datas de vencimento ou valores,
									devo solicitar esclarecimentos diretamente à empresa. Estou ciente que a Autorização
									de Débito pode ser cancelada por solicitação da {fatura.nomeempresa?.toUpperCase()}{' '}
									ou por mim, nos canais dos chats. Estou ciente que o cancelamento do débito
									automático não implica necessariamente no cancelamento do contrato com a{' '}
									{fatura.nomeempresa?.toUpperCase()}.
								</span>
							</div>
						</div>
					</>

				)}
			</>
		</section>
	);
}
